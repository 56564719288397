var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"tech-req__title"},[_vm._v(" "+_vm._s(_vm.tech.menu_material[_vm.language])+" ")]),_c('div',{staticClass:"tech-req__card"},[_c('div',{staticClass:"tech-req__card_container"},[(_vm.edit)?_c('Search',{attrs:{"required":"","label":_vm.tech.m_material[_vm.language],"default-value":_vm.partParams && _vm.partParams.material ? _vm.partParams.material : '',"clearable":"","query":{
          method: 'post',
          url: 'getMaterialsAsArray',
          data: {
            lang: this.language,
          },
          label: 'name',
          value: 'id',
        }},on:{"clear":function($event){return _vm.save('id_material', null)},"change":function($event){return _vm.save('id_material', $event.value)}}}):_vm._e(),(!_vm.edit)?_c('div',{staticStyle:{"margin-bottom":"15px"}},[_c('div',{staticClass:"tech-req__gears_param"},[_vm._v(" "+_vm._s(_vm.tech.m_material[_vm.language])+" ")]),_c('div',{staticClass:"tech-req__gears_letter"},[_vm._v(" "+_vm._s(_vm.partParams && _vm.partParams.material ? _vm.partParams.material : "-")+" ")])]):_vm._e(),(_vm.edit && _vm.partParams.id_material)?_c('Search',{attrs:{"label":_vm.tech.m_gost[_vm.language],"default-value":_vm.partParams && _vm.partParams.gost_material ? _vm.partParams.gost_material : '',"clearable":"","query":{
          method: 'post',
          url: 'getSearchStandards',
          label: 'standard_code',
          value: 'id',
        },"name":"gost_material"},on:{"clear":function($event){return _vm.save('id_gost_material', null)},"change":function($event){return _vm.save('id_gost_material', $event.value)}}}):_vm._e(),(!_vm.edit)?_c('div',{staticStyle:{"margin-bottom":"15px"}},[_c('div',{staticClass:"tech-req__gears_param"},[_vm._v(" "+_vm._s(_vm.tech.m_gost[_vm.language])+" ")]),_c('div',{staticClass:"tech-req__gears_letter"},[_vm._v(" "+_vm._s(_vm.partParams && _vm.partParams.gost_material ? _vm.partParams.gost_material : "-")+" ")])]):_vm._e()],1)]),(_vm.partParams && _vm.partParams.id_material)?_c('div',{staticClass:"tech-req__card"},[_c('div',{staticClass:"tech-req__card_container"},[(_vm.edit)?_c('Search',{attrs:{"label":_vm.tech.m_blank[_vm.language],"query":{
          method: 'post',
          url: 'getSearchBlanks',
          label: 'blank_type',
          value: 'id',
        },"default-value":_vm.partParams && _vm.partParams.blank_type ? _vm.partParams.blank_type : '',"clearable":"","name":"blank_type"},on:{"clear":function($event){return _vm.setBlankType(null)},"change":function($event){return _vm.setBlankType($event.value)}}}):_vm._e(),(!_vm.edit)?_c('div',{staticStyle:{"margin-bottom":"15px"}},[_c('div',{staticClass:"tech-req__gears_param"},[_vm._v(" "+_vm._s(_vm.tech.m_blank[_vm.language])+" ")]),_c('div',{staticClass:"tech-req__gears_letter"},[_vm._v(" "+_vm._s(_vm.partParams && _vm.partParams.blank_type ? _vm.partParams.blank_type : "-")+" ")])]):_vm._e(),(_vm.edit)?_c('Search',{attrs:{"label":_vm.tech.m_gost_blank[_vm.language],"default-value":_vm.partParams && _vm.partParams.gost_blank ? _vm.partParams.gost_blank : '',"clearable":"","query":{
          method: 'post',
          url: 'getSearchStandards',
          label: 'standard_code',
          value: 'id',
        },"name":"gost_blank"},on:{"clear":function($event){return _vm.save('id_gost_blank', null)},"change":function($event){return _vm.save('id_gost_blank', $event.value)}}}):_vm._e(),(!_vm.edit)?_c('div',{staticStyle:{"margin-bottom":"15px"}},[_c('div',{staticClass:"tech-req__gears_param"},[_vm._v(" "+_vm._s(_vm.tech.m_gost_blank[_vm.language])+" ")]),_c('div',{staticClass:"tech-req__gears_letter"},[_vm._v(" "+_vm._s(_vm.partParams && _vm.partParams.gost_blank ? _vm.partParams.gost_blank : "-")+" ")])]):_vm._e(),(
          _vm.partParams &&
          _vm.blankParams &&
          _vm.blankParams.sizes &&
          _vm.blankParams.sizes.filter(function (el) { return el !== '3d'; }).length > 0
        )?_c('div',[_c('div',{style:("margin-bottom: 15px; display: block; font-family: 'Noto Sans Mono'," +
            ' sans-serif; font-size: 16px; font-weight: 700; line-height: 35px;')},[_vm._v(" "+_vm._s(_vm.tech.m_blank_size[_vm.language])+" ")]),_c('div',[(_vm.blankParams && _vm.blankParams.img)?_c('div',[_c('img',{staticStyle:{"max-width":"100%"},attrs:{"src":_vm.blankParams.img}})]):_vm._e(),(_vm.blankParams && _vm.blankParams.sizes)?_c('div',{staticClass:"tech-req__material-and-blanks_sizes-container",staticStyle:{"width":"100%"}},_vm._l((_vm.blankParams.sizes.filter(function (el) { return el !== '3d'; })),function(size,i){return _c('div',{key:("sizes_" + i),staticClass:"tech-req__material-and-blanks_sizes-input",staticStyle:{"width":"100%"}},[(_vm.edit)?_c('div',{staticClass:"block__flex block__align-center",staticStyle:{"margin-bottom":"10px"}},[_c('div',{staticClass:"tech-req__gears_letter",staticStyle:{"margin-right":"10px","min-width":"18px"}},[_vm._v(" "+_vm._s(size)+" ")]),_c('Input',{attrs:{"default-value":_vm.partParams && _vm.partParams.blank_size && _vm.partParams.blank_size[size]
                      ? _vm.partParams.blank_size[size]
                      : '',"type":"number","max-width":80,"margin-bottom":0},on:{"change":function($event){return _vm.resetTimer(size, $event)}}}),_c('div',{staticClass:"tech-req__gears_letter",staticStyle:{"margin-left":"10px"}},[_vm._v(" "+_vm._s(_vm.tech.mm[_vm.language])+" ")])],1):_vm._e(),(!_vm.edit && _vm.partParams && _vm.partParams.blank_size && _vm.partParams.blank_size[size])?_c('div',{staticStyle:{"margin-bottom":"10px"}},[_c('span',[_c('span',{staticClass:"tech-req__gears_letter",staticStyle:{"min-width":"18px"}},[_vm._v(" "+_vm._s(size)+" ")]),_c('span',{staticClass:"tech-req__gears_param"},[_vm._v(" "+_vm._s(_vm.partParams && _vm.partParams.blank_size && _vm.partParams.blank_size[size] ? _vm.partParams.blank_size[size] : "")+" ")]),_c('span',{staticClass:"tech-req__gears_letter"},[_vm._v(" "+_vm._s(_vm.tech.mm[_vm.language])+" ")])])]):_vm._e()])}),0):_vm._e()])]):_vm._e(),(
          _vm.edit &&
          _vm.blankParams &&
          _vm.blankParams.sizes &&
          _vm.blankParams.sizes.filter(function (el) { return el === '3d'; }).length > 0
        )?_c('div',[_c('InputButton',{attrs:{"block":"","disabled":true,"type":"primary","text":_vm.tech.m_load_3d[_vm.language]}})],1):_vm._e()],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }